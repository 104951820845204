
/* page-title start */
.page-title {
	.height(497);
	overflow: hidden;
	position: relative;
	background-repeat: no-repeat;
	background-size: 100% auto;
   
    background-attachment: fixed;
    background-position: 0% 0%;
    @media only screen and (min-width: 2500px){
      background-size: cover;
    }
	@media @tablet{
        height: 23rem;
        background-size: cover;
        background-attachment: scroll;
    }
    @media @mobile {
        height: 20rem;
        background-size: cover;
        background-attachment: scroll;
	}
	.text {
		.pos-center;
		width:100%;
		.text-center;
		color:@white;
		z-index: 5;
		.header-text-wrap{
			display: flex;
      justify-content: center;
      @media @mobile{
        margin-top: 3.9374rem;
      }
        }
		  h1{
      .MtCB;
      text-shadow: -9.899px 9.899px 16px rgba(0, 0, 0, 0.67);
			.ttu;
			.font-size(70);
			.line-height(72);
			.letter-spacing(180);
      .padding(0,0,0,255);
       position: relative;
        @media @tablet{
          .font-size(60);
          .line-height(65);
          .padding(0,0,0,200);
        }
			@media @mobile{
				.font-size(40);
                .line-height(44);
                .padding(0,0,0,135);
            }
            span{
                position: relative;
                z-index: 2;
                em{
                  font-style: normal;
                  text-transform: none;
                }
            }
            .logo-ttl{
                .width(432);
                //.height(262);
                position: absolute;
                .pos-centerY;
                margin-top: 2rem;
                left: 0;
                z-index: 0;
                @media @tablet{
                    .width(332);
                    margin-top: 1.5rem;
                }
                @media @mobile{
                    margin-top: 1rem;
                    .width(232);
                }
            }
		}
		p {
			margin: 0;
		}
	}
}
/* page-title end */


.contact-block{
    background-color: @secondary;
    .padding(84,0,75,0);
    @media @tablet-portrait{
      .padding(64,0,65,0);
    }
    @media @mobile{
      .padding(40,0,40,0);
    }
    .text{
      padding-left: 3.125rem;
      display: flex;
      align-items: center;
      position: relative;
      span{
        color: @white;
        .font-size(50);
        letter-spacing: 0.18em;
        .MtCB;
        .ttu;
        white-space: nowrap;
        z-index: 2;
        @media @tablet-portrait{
          .font-size(35);
          padding-left: 1rem;
        }
        @media @mobile{
          .font-size(25);
          padding-left: 1rem;
        }
      }
      a{
        z-index: 2;
        margin-left: 2rem;
        overflow: hidden;
        .height(18);
        display: flex;
        align-items: center;
        .width(490);
        will-change: auto;
        transition: all .3s ease;
        @media @mobile {
          width: 100%;
        }
        &:hover{
          transform: translateX(10px)
        }
      }
      .line{
        .width(480);
        height: 2px;
        background-color: @primary;
        position: relative;
        @media @tablet-portrait{
          .width(280);
        }
        @media @mobile{
          .width(80);
        }
        &:after{
          content: "";
          position: absolute;
          right: -13px;
          .pos-centerY;
          border: 8px solid transparent;
          border-left: 16px solid @primary;
        }
      }
    }
    .logo-animate{
      .width(245);
      height: 100%;
      position: absolute;
      .left(-140);
      .top(-72);
      z-index: 1;
      @media @tablet-portrait{
        .width(190);
        left: -2.5rem;
        .top(-55);
      }
      @media @mobile{
        .width(120);
        left: -0.5rem;
        .top(-35);
      }
       object{
          width: 100%;
        
        }
       
    }
  }

  .block-ttl{
    .font-size(140);
    .MtCB;
   // color:#172017;
   color: @primary;

    .ttu;
    line-height: 6rem;
    text-align: center;
    text-shadow: -3.126px 17.727px 59px rgba(0, 0, 0, 0.5);
    opacity: 0.07;
    em{
      font-style: normal;
      text-transform: none;
    }
    // @media @desktop{
    //   .font-size(120);
    // }
    @media @tablet-portrait{
      .font-size(120);
      color: rgba(255, 255, 255, 0.4);
      opacity: 0.6;
    }
    @media @mobile{
      .font-size(90);
      color: rgba(255, 255, 255, 0.4);
      opacity: 0.6;

    }
    @media @mobile-xs{
      .font-size(60);
    }
 }
 .outer-block{
  .padding(0,0,0,0);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50% 0%;
  .inner-content-block{
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 50% 100%;
    .padding(94,0,130,0);
  }
}
  .services-content{
      .padding(0,0,0,0);
      .services-content-inner{
        .padding(94,0,130,0);
        @media @mobile{
          .padding(54,0,90,0); 
        }
      }
      .block-ttl{
        @media @mobile-xs{
          .font-size(60);
        }
     }
      .services-wrap{

        max-width: 71.875rem;
        margin: 0 auto;
        margin-top: -0.625rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @media @tablet, @mobile{
            max-width: 90%;
        }
        .item-link{
            display: block;
            .width(320);
            .margin(0,30,43,30);
            @media @mobile{
                width: 48%;
                margin: 0 1% 0 1% 2rem;
            }
            @media @mobile-xs{
                width: 100%;
            }
            &:hover{
              text-decoration: none;
              .image{
               .avatar {
                transform: scale(1.05);
               }
             }
            }
            &:first-child{
                .item{
                    .rhomb {
                        left: -0.5rem;
                        top: -0.5rem;
                      display: block;
                      .bottom{
                        display: none;
                      }
                      .right{
                        display: none;
                      }
                     }
                  }
            }
            &:last-child{
                .item{
                    .rhomb {
                        right: -0.5rem;
                        bottom: -0.5rem;
                      display: block;
                      .top{
                        display: none;
                      }
                      .left{
                        display: none;
                      }
                      
                     }
                  }
            }
          }
        .item{
            position: relative;
            background-color: @secondary;
            display: flex;
            flex-direction: column;
            box-shadow: -32.955px 1.727px 49px 0px rgba(0, 0, 0, 0.48);
            @media @mobile{
             .margin(15,30,60,30);
            }
            .rhomb {
              position: absolute;
              display: none;
             .width(92);
             .height(101);
              overflow: hidden;
              
              i{
                background: @primary;
                position: absolute;
              }
              .top{
                width: 100%;
                height: 1px;
                left: 0;
                top: 0;
              }
              .bottom{
                width: 100%;
                height: 1px;
                left: 0;
                bottom: 0;
              }
              .left{
                width: 1px;
                height: 100%;
                left: 0;
                bottom: 0;
              }
              .right{
                width: 1px;
                height: 100%;
                right: 0;
                bottom: 0;
              }
             }
            .image{
              width: 100%;
              overflow: hidden;
              .avatar {
               // .height(320);
                padding-bottom: 100%;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                transition: all .3s ease;
              }
            }
            .text{
              background-color: @black;
              color:@white;
              .padding(14,10,14,22);
     
              p{
                .MtB;
                 .font-size(20);
                 .line-height(26);
                 margin: 0;
                 .ttu;
              }
            }
          }
      }
  }


  .team-page{
    background-color: @black;
    .outer-block{
      .inner-content-block{
        .padding(94,0,226,0);
        @media @mobile{
          .padding(80,0,100,0);
        }
      }
    }
    .team-wrap{
      display: flex;
      flex-direction: column;
      max-width: 68.75rem;
      margin: 0 auto;
      margin-top: 3rem;
      @media @tablet,@mobile{
        max-width: 90%;
        margin-top: 0;
      }
      
        .item{
          .margin(32,0,38,47);
          .padding(38,0,28,239);
          position: relative;
          background-color: @secondary;
          box-shadow: -32.955px 1.727px 49px 0px rgba(0, 0, 0, 0.48);
          @media @tablet{
            .padding(30,11,40,175);
          }
          @media @tablet-portrait{
            .padding(30,11,40,239);
          }
          @media @mobile{
            height: unset;
            .margin(65,30,55,30);
            .padding(170,20,40,40);
          }
          .rhomb {
            position: absolute;
            display: block;
           .width(90);
           .height(90);
            overflow: hidden;
            right: -0.5rem;
            bottom: -0.5rem;
            i{
              background: @primary;
              position: absolute;
            }
            .top{
              width: 100%;
              height: 1px;
              left: 0;
              top: 0;
            }
            .bottom{
              width: 100%;
              height: 1px;
              left: 0;
              bottom: 0;
            }
            .right{
              width: 1px;
              height: 100%;
              right: 0;
              top: 0;
            }
            .left{
              width: 1px;
              height: 100%;
              left: 0;
              bottom: 0;
            }
           }
          .image{
            position: absolute;
            .left(-45);
            .top(-35);
            padding: 0 1.375rem 1.625rem 0;
            @media @mobile{
              .pos-centerX;
              .top(-40);
            }
            .avatar {
              .width(250);
              .height(320);
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: cover;
              background-color: #212121;
              box-shadow: -32.955px 1.727px 49px 0px rgba(0, 0, 0, 0.48);
              @media @tablet{
                .width(150);
              .height(200);
              }
              @media @tablet-portrait{
                .width(250);
                .height(320);
              }
              @media @mobile{
                .width(140);
               .height(180);
              }
            }
          }
          h2{
            .MtB;
            .font-size(20);
            .line-height(26);
            margin-bottom: 0.375rem;
          }
          .position{
            .font-size(14);
            .line-height(20);
            .Mt;
            margin-bottom: 0;
          }
          .holder{
            .height(225);
            overflow-y: hidden;
            padding-right: 1rem;
            margin-top: 2rem;
            @media @mobile{
              height: unset;
            }

            .mCSB_inside>.mCSB_container {
              margin-right: 5px;
          }
            p{
              .Mt;
              .font-size(15);
              .line-height(25);
            }
          }
        }
      
    }
  }

  .faq-page{
    .block-ttl{
      .font-size(140);
      .MtCB;
      color:#172017;
      .ttu;
      line-height: 6rem;
      text-align: center;
      text-shadow: -3.126px 17.727px 59px rgba(0, 0, 0, 0.5);
      opacity: 0.9;
      em{
        font-style: normal;
        text-transform: none;
      }
      // @media @desktop{
      //   .font-size(120);
      // }
      @media @tablet-portrait{
        .font-size(120);
      color: rgba(255, 255, 255, 0.4);
      opacity: 0.6;
      }
      @media @mobile{
        .font-size(90);
        color: rgba(255, 255, 255, 0.4);
        opacity: 0.6;
      }
      @media @mobile-xs{
        .font-size(60);
      }
   }
    .outer-block{
      .inner-content-block{
        min-height: 69rem;
        @media @tablet{
          min-height:unset;
          background-size: cover;
          .padding(40,0,170,0);
        }
        @media @mobile {
          min-height:unset;
          background-size: cover;
          .padding(40,0,100,0);
        }
      }
    }
    .faq-accordion {
      max-width: 68.75rem;
      margin: 0 auto;
      padding:1rem 0 0;
      @media @tablet,@mobile{
        max-width: 90%;
      }
  
      .faq-item{
        box-shadow:none;
        border-radius:0;
        background: none;
        border:0;
        .margin(0,0,8,0);
        position: relative;
        background-color: @secondary;
        .card-header {
          border:0;
          padding: 0;
          background-color: @secondary;
        }
        .text{
          background-color: @secondary;
          .padding(10,60,30,66);
          .font-size(15);
          .line-height(25);
          position: relative;
          z-index: 4;
          @media @mobile {
            .padding(10,50,20,40);
          }
          p {
            margin: 0 0 1rem;
          }
        }
      }
      .head-link {
        background: none;
        border:0;
        .padding(25,80,25,66);
        .font-size(18);
        .line-height(30);
        .MtB;
        width:100%;
        position: relative;
        color: @white;
        cursor: pointer;
        text-align: left;
        min-height: 5rem;
        &:hover {
          text-decoration: none;
        }
        &:focus {
          outline: none;
          @media @mobile {
            outline: none;
          }
        }
        @media @tablet {
          .font-size(18);
          .line-height(26);
          .padding(25,60,25,66);
        }
        @media @mobile {
          .font-size(16);
          .line-height(20);
          .padding(25,50,25,40);
        }
        .arrow{
          .width(17);
          .height(28);
          top:32%;
          position: absolute;
          .right(25);
          transition: all .3s ease;
          transform: rotate(0);
          @media @mobile{
            .width(10);
          .height(12);
          }
        }
        .icon {
          position: absolute;
          .width(56);
          .height(52);
          .left(-20);
          .top(8);
          .pos-centerY;
          display: flex;
          align-items: center;
          justify-content: center;
          color:@white;
          .font-size(36);
          .MtB;
          background-color: rgb(11, 197, 4);
            box-shadow: -32.955px 1.727px 49px 0px rgba(0, 0, 0, 0.48);
          @media @mobile {
            .width(32);
            .height(32);
            .font-size(18);
            .left(-15);
          }
          &:before{
            content: "";
            left: 0;
            position: absolute;
            top: 0;
            width:2px;
            background-color: @primary;
            .height(275);
            transition: all .3s ease;
          }
        }
        &.collapsed {
          box-shadow:none;
          &:hover {
            .icon {
              background: @primary;
            }
          }
          .icon {
            color:@white;
            background-image: -moz-linear-gradient( -138deg, rgb(23,40,22) 0%, rgb(13,91,11) 100%);
              background-image: -webkit-linear-gradient( -138deg, rgb(23,40,22) 0%, rgb(13,91,11) 100%);
              background-image: -ms-linear-gradient( -138deg, rgb(23,40,22) 0%, rgb(13,91,11) 100%);
              background-image: linear-gradient( -138deg, rgb(23,40,22) 0%, rgb(13,91,11) 100%);
              &:before{
                height: 0;
                transition: all .3s ease;
              }
          }
          .arrow{
            transform: rotate(90deg);
            transition: all .3s ease;
            
          }
        }
      }
    }
  }
 

  .services-inner-page{
    .about-section{
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 100%;
      background-color: @black;
      .padding(180,0,230,0);
      position: relative;
      @media @tablet{
       background-size: 100% auto;
       .padding(130,0,190,0);
       }
       @media @mobile{
       .padding(90,0,150,10);
       background-size: 100% auto;
       }
     .logo-animate{
       .width(240);
       height: 100%;
       position: absolute;
       .left(-145);
       top:-4.9375rem;
       z-index: 0;
       @media @desktop-xl{
         .width(220);
         .left(-95);
       }
       @media @tablet{
         .width(200);
         left: 0;
         .top(-107);
       }
       @media @mobile{
         .width(170);
         left: -1rem;
         .top(-50);
       }
        object{
           width: 100%;
       }
     }
     .text-side{
       position: relative;
       .padding(0,40,0,0);
       z-index: 1;
       @media @mobile{
         padding: 0;
         margin-bottom: 2rem;
       }
       h3{
         .MtB;
         .font-size(35);
         margin-bottom: 1rem;
         z-index: 1;
         @media @mobile{
           margin-bottom: 3rem;
         }
       }
       .text-scroll{
         .height(478);
         @media @mobile{
           height: unset;
         }

       }
       p{
         z-index: 1;
         margin-bottom: 2rem;
         .line-height(30);
       }
       .btn-wrap{
         margin-top: 2rem;
         
         .link{
           .MtB;
           .font-size(14);
           text-decoration: none;
           color: @white;
           .ttu;
           display: flex;
           align-items: center;
           transition: all .3s ease;
           &:hover{
              transform: translateX(-7px);
              transition: all .3s ease;
           }
           .icon{
            width: 1.25rem;
            margin-right: 1rem;
            svg{
              path{
                fill: @primary!important;
              }
              }
            }
         }
       }
     }
      .image-side {
        margin: 0 auto;
        .width(662);
        .height(452);
        margin-right:-8.75rem;
        position: relative;
        @media @tablet{
        .width(600);
        .height(410);
         margin-right:0;
        }
        @media @tablet-portrait,@mobile{
          margin-top: 3rem;
          margin-right:0;
          margin: 0 auto;
        }
        @media @mobile-xs{
          .width(324);
          height: 14.125rem;
          margin: 0 auto;
        }
        .rhomb {
         position: absolute;
         display: block;
        .width(92);
        .height(105);
         overflow: hidden;
         @media @mobile-xs{
           .width(72);
           .height(85);
         }
         i{
           background: @primary;
           position: absolute;
         }
         .top{
           width: 100%;
           height: 1px;
           left: 0;
           top: 0;
         }
         .bottom{
           width: 100%;
           height: 1px;
           left: 0;
           bottom: 0;
         }
         .right{
           width: 1px;
           height: 100%;
           right: 0;
           top: 0;
         }
         .left{
           width: 1px;
           height: 100%;
           left: 0;
           bottom: 0;
         }
        }
        .img-box{
          position: absolute;
          box-shadow: -32.955px 1.727px 49px 0px rgba(0, 0, 0, 0.48);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: @secondary;
          
        }
        .left-btn-img{
          width: 48%;
         // .height(453);
          padding-bottom: 35%;
          .pos-centerY;
          left: 0;
          z-index: 4;
          .rhomb {
            .width(92);
            .height(105);
            .top(-8);
            .left(-8);
            @media @mobile-xs{
            .width(72);
            .height(85);
            .top(-11);
            .left(-10);
          }
          }
        }
        .right-top-img{
         // .height(228);
          padding-bottom: 35%;
          width: 48%;
          top:0;
          right: 0;
        }
        .right-btn-img{
          width: 40%;
          //.height(199);
          padding-bottom: 30%;
           bottom:0;
          right:8%;
          .rhomb {
            .width(42);
            .height(85);
           .bottom(-10);
           .right(-8);
           }
        }
        .bg-text{
          position: absolute;
          width: 100%;

          &.text-top{
            text-align: right;
           bottom:103%;
           left: 0;
          }
          &.text-btm{
           top: 103%;
           
          }
          p{
           font-family: 'BankGothic Md BT';
          color: @white;
          margin:0;
          .font-size(30);
          line-height: 1.8rem;
          .ttu;
          letter-spacing: 0.01em;
          @media @mobile{
            
          .font-size(27);
          }
          @media @mobile{
           .font-size(20);
           .line-height(20);
          }
          span{
            .font-size(50);
            @media @mobile{
             .font-size(33);
           }
          }
          @media @mobile-xs{
           .font-size(20);
           .line-height(20);
          }
        }
      }
     }
   }
  }



  .contact-page{
    .inner-content-block{
      .padding(141,0,0,0);
      @media @desktop-xl,@tablet,@mobile{
        background-position: 0% 100%;

        background-size: cover;
      }
    }
  
  .contact-wrap{
    display: flex;
    max-width: 91.5rem;
    margin: 0 auto;
    position: relative;
   justify-content: flex-end;
   
    @media @tablet-portrait,@mobile{
      justify-content: center;
    }
    .people{
      .width(820);
      left: -100%;
      height: auto;
      bottom: 0;
      z-index: 3;
      position: absolute;
      @media @tablet{
        .width(700);
        left: -60%;
      }
      @media @tablet-portrait{
        position: relative;
        margin-left: auto;
        margin-top: -5rem;
        width: 70%;
        left: unset;
        display: block;
        
      }
      @media @mobile{
        position: relative;
        margin: 0 auto;
        margin-top: -3rem;
        width: 100%;
        left: unset;
      }
    }
    .ttl-text-wrap{
      position: absolute;
      left: -103%;
      z-index: 2;
      @media @tablet{
        top:-20%;
        left: -70%;
      }
      @media @tablet-portrait,@mobile{
        position: relative;
        left: unset;
        top: unset;
        margin-bottom: 8rem;
      }
      h1{
        white-space: nowrap;
        .MtCB;
        text-shadow: -9.899px 9.899px 16px rgba(0, 0, 0, 0.67);
        .ttu;
        .font-size(70);
        .line-height(72);
        .letter-spacing(180);
        .padding(0,0,0,255);
        .margin(105,0,0,0);
         position: relative;
          @media @tablet{
            .font-size(60);
            .line-height(65);
            .padding(0,0,0,200);
          }
        @media @mobile{
          .font-size(40);
            .line-height(44);
            .padding(0,0,0,135);
            .margin(15,0,0,0);
        }
        @media @mobile-xs{
          .font-size(35);
            .line-height(44);
            .padding(0,0,0,100);
        }
        @media only screen and (max-width: 375px){
          .font-size(30);
            .line-height(44);
            .padding(0,0,0,80);
        }
        span{
          position: relative;
          z-index: 2;
        }
        .logo-ttl{
          .width(432);
          position: absolute;
          .pos-centerY;
          margin-top: 2rem;
          left: 0;
          z-index: 0;
          @media @tablet{
            .width(332);
            margin-top: 1.5rem;
          }
          @media @mobile{
            margin-top: 1rem;
            .width(232);
          }
          @media @mobile-xs{
            margin-top: 1rem;
            .width(190);
          }
        }
      }
    }
    .contact-form-block{
      width:50%;
      position: relative;
      .padding(0,0,120,0);
      @media @tablet{
        width: 60%;
        margin-top: 18rem;
      }
      @media @tablet-portrait,@mobile{
        width:100%;
        margin-top: 0;
        margin: 0 auto;
        .padding(0,0,0,0);
      }
      .form-holder{
        background-color:@secondary;
        .padding(43,51,66,140);
        box-shadow: -32.955px 1.727px 49px 0px rgba(0, 0, 0, 0.48);
        @media @tablet{
          .padding(43,51,66,50);
          width:90%;
            margin: 0 auto;
        }
        @media @mobile{
          .padding(40,25,40,25);
          width:90%;
          margin: 0 auto;
        }
        .ttl{
          margin-bottom: 1.5rem;
          h3{
            .MtB;
            .font-size(18);
            .line-height(30);
            margin-bottom: 1rem;
          }
          p{
            .font-size(15);
            margin: 0;
          }
        }
        .btn-wrap{
          text-align: right;
          @media @mobile-xs{
            .btn{
              width: 100%;
            }
          }
        }
      }
    }
  }
  .map-section{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .height(370);
    .marker{
      position: absolute;
      color: @primary;
      .width(20);
      .height(28);
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}