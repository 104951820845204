
.header {
	z-index: 100;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    color: #fff;
    -webkit-transition: 350ms ease-in-out;
    -moz-transition: 350ms ease-in-out;
	transition: 350ms ease-in-out;
	@media @mobile{
		background: @black;
	}
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 170px;
		background: url(../img/shadow.png) repeat-x;
		-webkit-transition: 350ms ease-in-out;
		-moz-transition: 350ms ease-in-out;
		transition: 350ms ease-in-out;
		@media @tablet{
		top: -80%;
		}
		@media @mobile{
			opacity: 0;
			height: 0;
		}
	}
	&.scrolling{
		.transition(all 500ms ease);	
		background: @black;
		&:before {
			opacity: 0;
			height: 0;
		}
	}

	.logo {
		transition: 350ms ease-in-out;
		.width(330);
		flex-shrink: 0;
		z-index: 2;
		padding-top: 0.375rem;
		@media @tablet{
			margin-right: 3rem;
			.width(250);
			text-align: center;
		}
		@media @mobile {
			.width(200);
			.margin(4,0,4,0);
		}
		@media @mobile-xs {
			.margin(4,0,4,0);
			.width(200);
		}
		img {
			width: 100%;
			height: auto;
		}
	}
	.header-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width:100%;
		z-index: 2;
		.padding(0,50,0,50);
		@media @mobile {
			.padding(0,20,0,20);
		}
	}
	.nav-holder {
		width:100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 200;
		@media @tablet, @mobile {
			position: fixed;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			.padding(140,0,0,0);
			background: @black;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: column;
			.transition(all 0.5s ease);
			&.show {
				right:0;
			}
		}
		@media @mobile {
			width:100%;
		}
		.nav-logo {
			display: none;
			position: absolute;
			top:0.7rem;
			.pos-centerX;
			width:16rem;
			@media @tablet, @mobile {
				display: block;
			}
		}
		& > ul {
			display: flex;
			justify-content: space-around;
			align-items: center;
			list-style: none;
			margin: 0;
			@media @tablet, @mobile {
				height: 100%;
				width:100%;
				overflow: auto;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				display: block;
			}
			& > li {
				.margin(0,12,0,12);
				position: relative;
				@media @tablet, @mobile {
					margin: 0;
					width:100%;
					border-top:1px solid rgba(255,255,255,0.1);
					&:last-child {
						border-bottom:1px solid rgba(255,255,255,0.1);
					}
				}
				&:hover {
					.dropdown {
						display: block;
					}
				}
				& > a {
					display: block;
					.ttu;
					.font-size(14);
					.line-height(20);
					color:@white;
					white-space: nowrap;
					.MtB;
					@media @tablet, @mobile {
						display: block;
						.font-size(20);
						padding: 1rem 2rem;
						text-align: center;
						&:after{
							display: none;
						}
					}
					&:after {
						content: "";
						width: 0;
						height: 0;
						border-top: 2px solid;
						bottom: -0.3rem;
						position: absolute;
						right: 0;
						-webkit-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
						-moz-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
						-ms-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
						-o-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
						transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
					}
					&:hover {
						text-decoration: none;
						color: @primary;
					}
					&:hover:after {
						width: 100%;
						right: auto;
						left: 0;
					}
					&:active,
					&:focus{
						color: @primary;
						text-decoration: none;
					}
				}
				 &.active {
				 	a {
				 		text-decoration: none;
						 color:@primary;
						 @media @tablet,@mobile{
							 &::after{
								 display: none;
							 }
						 }
						&:after {
							content: "";
							width: 100%;
							height: 0;
							border-top: 2px solid;
							bottom: -0.3rem;
							position: absolute;
							right: 0;
							-webkit-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
							-moz-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
							-ms-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
							-o-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
							transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
						}
				 	}
				 }
				.dropdown {
					position: absolute;
					.width(250);
					left:0;
					.top(15);
					.padding(15,0,0,0);
					display: none;
					border-radius: 0;
					@media @tablet, @mobile {
						display: block;
						position: static;
						padding: 0;
						width:auto;
					}
					& > ul {
						box-shadow: 0.939px 5.926px 16px 0px rgba(0, 0, 0, 0.2);
						list-style: none;
						margin: 0;
						background: @secondary;
						background-size: auto 0.375rem;
						@media @tablet, @mobile {
							background: none;
							border-radius: 0;
							border-top:1px solid rgba(255,255,255,0.1);
							box-shadow: none;
						}
						& > li {
							margin: 0;
							.padding(8,10,8,19);
							@media @tablet, @mobile {
								margin: 0;
								width:100%;
								border-bottom:1px solid rgba(255,255,255,0.1);
								&:last-child {
									border-bottom:0;
								}
							}
							&:hover{
								cursor: pointer;
								background-color: @primary;
								// & > a {
								// 	color: @primary;
								// }
							}
							&.active {
								background-color: @primary;
								cursor: pointer;
								@media @tablet,@mobile{
									background-color: unset;
								}
								& > a {
									color: @white;
									@media @tablet,@mobile{
										color: @primary;
									}
								}
							}
							& > a {
								.Mt;
								.ttu;
								color: @white;
								.font-size(14);
								.line-height(20);
								&:hover {
									text-decoration: none;
								}
								@media @tablet, @mobile {
									display: block;
									padding: .5rem 1rem;
									.font-size(18);
    								text-align: center;
									color:@white;
								}
							}

						}
					}
				}
			}
		}
	}

	.right-side{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.phone{
			z-index: 2;
			white-space: nowrap;
			@media @tablet{
				margin-right: 3rem;
			}
			@media @mobile{
				margin-right: 2rem;
				span{
					display: none;
				}
			}
			span{
				.MtB;
				.font-size(14);
			}
			&:hover{
					text-decoration: none;
				i{
					-webkit-animation-name: hvr-pulse;
					animation-name: hvr-pulse;
					-webkit-animation-duration: 1s;
					animation-duration: 1s;
					-webkit-animation-timing-function: linear;
					animation-timing-function: linear;
					-webkit-animation-iteration-count: infinite;
					animation-iteration-count: infinite;
	
				}
			}
			i{
				color: @primary;
			}
	
		}
	}
	

}




/*hamburger btn*/
.btn-lines {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	.height(2);
	background-color: @primary;
	content: "";
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;

}
.c-hamburger {
	display: none;
	position: relative;
	z-index: 1000;
	margin: 0;
	padding: 0;
	.width(26);
	.height(20);
	flex-shrink: 0;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	border: none;
	cursor: pointer;
	-webkit-transition: all 0.3s ease 0.3s;
	transition: all 0.3s ease  0.3s;
	&:focus {
		outline: none;
	}
	@media @tablet, @mobile {
		display: block;
	}
	span {
		display: block;
		position: absolute;
		.top(9);
		left: 0;
		right: 0;
		.height(2);
		background: @primary;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
	span::before {
		&:extend(.btn-lines);
		.top(-7);
	}
	span::after {
		&:extend(.btn-lines);
		.bottom(-7);
	}
}
.c-hamburger--htx {
	background-color: transparent;
	&.active {
		&:before {
			display: none;
		}
		span {
			background: none;
		}
		span::before {
			top: 0;
			transform:rotate(45deg);
			-webkit-transform:rotate(45deg);
			background: @white;
		}
		span::after {
			bottom: 0;
			transform:rotate(-45deg);
			-webkit-transform:rotate(-45deg);
			background: @white;
		}
	}
}
/*hamburger btn*/