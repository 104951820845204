.footer {
  color:@white;
  background-color: @black;
  .font-size(16);
  .line-height(25);
  .padding(50,0,62,0);
  position: relative;
  overflow: hidden;
  @media @mobile{
    .padding(30,0,60,0);
  }
.separation{
  height: 2px;
  width: 100%;
  background-color: #141414;
  display: none;
}
  .top-footer{
    .padding(0,0,40,0);
    @media @mobile-xs{
      .padding(0,0,25,0);
    }
    .footer-inner{
      display: flex;
      flex-direction: column;
      align-items: center;
      @media @mobile{
        max-width: 90%;
        margin: 0 auto;
        p{
          text-align: center;
        }
      }
    }
    .logo {
      .width(168);
      height: auto;
      margin-bottom: 1rem;
      @media @mobile {
        margin-top: 1rem;
      }
    }
    .tel{
      display: flex;
      justify-content: center;
      align-items: center;
      @media @mobile-xs{
        flex-direction: column;
      }
      p{
        margin: 0;
      }
      a{
        .MtB;
        span{
          .Mt;
        }
      }
      .sep{
        .margin(0,28,0,24);
        .circle(4px);
        background-color: @primary;
      }
    }
  }
  .bottom-line{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #000;
    z-index: 200;
    border-top: 2px solid #141414;
    padding: 0rem 0 1.25rem 0;

    .social {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      z-index: 5;
      text-align: center;
      .margin(20,0,0,0);
      //@media @mobile {
      //  .margin(13,0,0,0);
      //}
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        color:@white;
        .margin(0,50,0,50);
        @media @mobile{
          .margin(0,20,0,20);
        }
        &:hover {
          text-decoration: none;
          transform: scale(1.1);
          cursor: pointer;
          color: @primary;
        }
        i {
        //  .font-size(22);
          .font-size(26);
        }
      }
    }
  }
}

.cookie-block {
  position: fixed;
  left:0;
  bottom: 0;
  width:100%;
  background: fade(@black, 90%);
  .padding(16,0,16,0);
  z-index: 999;
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    @media @mobile-xs {
      flex-direction: column;
    }
    .text {
      text-align: center;
      color:@white;
      .font-size(16);
      .line-height(32);
      .padding(0,40,0,0);
      @media @mobile-xs {
        .font-size(15);
        .line-height(20);
        .padding(0,0,16,0);
      }
      h6 {
        .font-size(18);
        .margin(0,0,5,0);
        font-weight: 700;
      }
      p {
        margin: 0;
        color: @white;
      }
    }
   
  }
}

