.home-page {
  .banner {
    position: relative;
    overflow: hidden;
    height: auto;
    background-color: @black;
    @media @tablet,@mobile {
      height: auto;
    }
    @media @mobile {
      margin-top: 4.25rem;
    }
    .banner-pic {
      width: 100%;
      .full;
      @media @tablet{
        top:0.625rem;
      }
    }
    .banner-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      z-index: 10;
      .text {
        position: absolute;
        &.text-left{
          padding-left: 2rem;
          margin-bottom: 3rem;
          right: 58%;
          .top(160);
          top: 20%;
          @media @tablet{
            right: 60%;

            top: 23%;
          }
          p{
            color: @white;
            .font-size(70);
            margin: 0;
            white-space: nowrap;
            .line-height(72);
            .ttu;
            .MtCB;
            letter-spacing: .18em;
            @media @tablet{
              .font-size(60);
            }
            @media @tablet-portrait{
              .font-size(45);
            .line-height(45);
            }
            @media @mobile{
              .font-size(24);
            .line-height(24);
            }
          }
        }
        &.text-right{
          right: 17%;
          top:50%;
          @media only screen and (min-width: 2100px){
          right: 22%;
        }
          p{
            color: @white;
            font-family: 'BankGothic Md BT';
            .font-size(60);
            margin: 0;
            .line-height(60);
            .ttu;
            letter-spacing: 0.1em;
            @media @tablet-portrait{
              .font-size(45);
            .line-height(45);
            }
            @media @mobile{
              .font-size(24);
            .line-height(24);
            }

          }
        }


      }
    }
    .bg-banner {
      width: 100%;
      height: auto;
    }
    .video-holder {
      video {
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100% ;
        @media screen and (min-device-aspect-ratio: 16/9) {
          height: 400%;
          top: -150%;
        }
        @media screen and (max-device-aspect-ratio: 16/9) {
          width: 400%;
          left: -150%;
        }
        @media @mobile {
          margin-left: 0;
          top: -20px;
          height: ~"calc(100% + 20px)";
        }

      }
    }
  }
/* banner end */

  ///*about-block*/
  
  .about-section{
   background-size: cover;
   background-repeat: no-repeat;
   background-position: 50% 100%;
   background-color: @black;
   .padding(180,0,230,0);
   position: relative;
   @media @tablet{
    background-size: 100% auto;
    .padding(130,0,190,0);
    }
    @media @mobile{
    .padding(90,0,50,10);
    background-size: 100% auto;
    }
  .logo-animate{
    .width(240);
    height: 100%;
    position: absolute;
    .left(-145);
    .top(-127);
    z-index: 0;
    @media @desktop{
      .left(-85);
    }
    @media @tablet{
      .width(200);
      left: 0;
      .top(-107);
    }
    @media @mobile{
      .width(170);
      left: -1rem;
      .top(-50);
    }
     object{
        width: 100%;
    }
  }
  .text-side{
    position: relative;
    .padding(0,40,0,0);
    z-index: 1;
    @media @mobile{
      padding: 0;
    }
    h3{
      .MtB;
      .font-size(35);
      margin-bottom: 2rem;
      z-index: 1;
      @media @mobile{
        margin-bottom: 3rem;
      }
    }
    p{
      z-index: 1;
      margin-bottom: 2rem;
    }
  }
   .image-side {
     margin: 0 auto;
     .width(662);
     .height(514);
     position: relative;
     @media @tablet{
      .width(600);
      .height(485);
       margin: 0 auto;
       margin-left: -1rem;
     }
     @media @tablet-portrait,@mobile{
       margin-top: 3rem;
     }
     @media @mobile-xs{
       .width(324);
       height: 19.125rem;
     }
     .rhomb {
      position: absolute;
      display: block;
     .width(92);
     .height(105);
      overflow: hidden;
      @media @mobile-xs{
        .width(72);
        .height(85);
      }
      i{
        background: @primary;
        position: absolute;
      }
      .top{
        width: 100%;
        height: 1px;
        left: 0;
        top: 0;
      }
      .bottom{
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
      }
      .right{
        width: 1px;
        height: 100%;
        right: 0;
        top: 0;
      }
      .left{
        width: 1px;
        height: 100%;
        left: 0;
        bottom: 0;
      }
     }
     .img-box{
       position: absolute;
       box-shadow: -32.955px 1.727px 49px 0px rgba(0, 0, 0, 0.48);
       background-size: cover;
       background-position: center;
       background-repeat: no-repeat;
     }
     .left-btn-img{
       width: 48%;
      // .height(453);
       padding-bottom: 68%;
       bottom: 0;
       left: 0;
       z-index: 4;
       .rhomb {
       .width(92);
       .height(105);
       .top(-29);
       .left(-25);
       @media @mobile-xs{
        .width(72);
        .height(85);
        .top(-11);
       .left(-10);
      }
       }
     }
     .right-top-img{
      // .height(228);
       padding-bottom: 35%;
       width: 48%;
       top:0;
       right: 0;
     }
     .right-btn-img{
       width: 40%;
       //.height(199);
       padding-bottom: 30%;
        .bottom(70);
       right:8%;
       .rhomb {
        .width(80);
        .height(130);
        .bottom(-25);
        .right(-11);
        @media @mobile-xs{
          .width(60);
          .height(90);
          .bottom(-10);
        .right(-10);
        }
        }
     }
     .bg-text{
       position: absolute;
       top: 0;
       right: 0;
       width: 48%;
      
       &.text-top{
        top: 0;
        left: 0;
       }
       &.text-btm{
        top: 90%;
        @media @mobile-xs{
          top:83%;
        }
       }
       p{
        font-family: 'BankGothic Md BT';
       color: @white;
       margin:0;
       .font-size(36);
       line-height: 1.8rem;
       .ttu;
       @media @mobile{
         
       .font-size(27);
       }
       @media @mobile{
        .font-size(20);
        .line-height(20);
       }
       span{
         .font-size(50);
         @media @mobile{
          .font-size(33);
        }
       }
       @media @mobile-xs{
        .font-size(20);
        .line-height(20);
       }
     }
   }
  }
}

.testim-section{
  background-color: @secondary;
  .padding(110,0,90,0);
  @media @tablet-portrait,@mobile{
    .padding(50,0,50,0);
  }
  .testim-slider{
    .item{
      .item-inner{
        .margin(0,0,70,0);
        .padding(27,0,0,0);
        display: flex;
        align-items: flex-end;
        @media @tablet-portrait,@mobile{
          flex-direction: column;
        }
        .icon-block{
          .width(286);
          margin-right: 3.75rem;
          .padding(10,10,10,14);
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          @media @tablet-portrait,@mobile{
            margin: 0 auto;
            margin-bottom: 2rem;
          }
          .deco-1{
            position: absolute;
            .width(190);
            z-index: 4;
            left: 0;
            bottom:1rem;
          }
          .deco-2{
            position: absolute;
            .width(180);
            z-index: 3;
            right: 0;
            top:0.5rem;
          }
          .elipse{
            .width(262);
            .height(166);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 2;
            position: relative;
          }
          .icon-wrap{
            .circle(12.125rem);
            background-color: transparent;
            border: .125rem solid @primary;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 3;
            .icon{
              .circle(10.625rem);
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              box-shadow: -32.955px 1.727px 49px 0px rgba(0, 0, 0, 0.48);
              background-color: @black;
              flex-shrink: 0;
            }
          }
          
        }
        .text-block{
          position: relative;
          max-width: 50rem;
          @media @tablet-portrait{
            max-width: 80%;
            margin: 0 auto;
          }
          @media @mobile{
            max-width: 85%;
            margin: 0 auto;
          }
          p{
            .font-size(18);
            .line-height(36);
            margin: 0;
            position: relative;
            z-index: 1;
          }
          .description{
            color: @white;
            margin-bottom: 2rem;
            z-index: 1;
            position: relative;
            .right-deco{
              position: absolute;
              top:53%;
              right: 0;
              .width(168);
              z-index: 0;
              @media @mobile{
                .width(100);
               top:75%;
              }
            }
            .left-deco{
              position: absolute;
              .top(-32);
              .left(-118);
              .width(168);
              z-index: 0;
              @media @tablet-portrait{
                .left(-88);
                .top(-52);
              }
              @media @mobile{
                .width(100);
                left: -5%;
                .top(-52);
              }
            }
            p{
              font-family: 'Montserrat', sans-serif;
              font-style: italic;
              font-weight: 500;
            }
          }
          .sign{
            position: relative;
            z-index: 1;
            p{
              .line-height(30);
              color: @primary;
            }
          }
        }
      }
    }
    .slick-dots {
     margin-left:21rem;
			display: flex;
      align-items: center;
      @media @tablet-portrait,@mobile{
        justify-content: center;
        margin: 0;
      }
			li {
				list-style: none;
				display:flex;
				.margin(0,7,0,7);
			
				button {
					border:0;
					.width(15);
					.height(15);
					padding: 0;
					overflow: hidden;
					text-indent: -999px;
					border-radius: 50%;
					flex-shrink: 0;
					background: #333333;
				}
				&.slick-active {
					button {
						background: @primary;
						&:focus{
							outline: none;
						}
					}
				}
			}
		}
  }
}

.calendar-section{
  .padding(110,0,60,0);
  background-size: cover;
   background-repeat: no-repeat;
   background-position: 50% 100%;
   background-attachment: fixed;
  position: relative;
  @media @tablet-portrait,@mobile{
   background-attachment: scroll;
  .padding(30,0,90,0);
  }
  .man-pic{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    @media @mobile{
      display: none;
    }
  }
   .block-ttl{
      .font-size(140);
      .MtCB;
      color: rgba(255, 255, 255, 0.4);
      .ttu;
      line-height: 6rem;
      text-align: center;
      text-shadow: -3.126px 17.727px 59px rgba(0, 0, 0, 0.5);
      opacity: 0.6;
      @media @mobile{
        .font-size(100);
      }
      @media @mobile-xs{
        .font-size(60);
      }
   }
   .container-big{
    @media @mobile{
     max-width: 100%; 
    }
  }
  .calendar-slider{
    margin-top: -2rem;
    
    .item{
      .item-inner{
        .margin(48,50,90,60);
        .padding(55,18,35,34);
        position: relative;
        background-color: @secondary;
        .height(225);
        box-shadow: -1.116px 15.961px 20px 0px rgba(0, 0, 0, 0.48);
        @media @mobile{
          height: unset;
          margin: 4rem 2.875rem 3.125rem 3rem;
        }
        .date{
          position: absolute;
          .top(-31);
          .left(-29);
          background-color: @primary;
          .ttu;
          color: @white;
          .height(50);
          display: flex;
          align-items: center;
          .padding(0,20,0,20);
          .MtB;
          .font-size(20);
          box-shadow: 0 1.5rem 2.875rem rgba(0,0,0,.3);
          box-shadow: -1.116px 15.961px 20px 0px rgba(0, 0, 0, 0.48);
          &:before{
            content: "";
            position: absolute;
            width: 2px;
            background-color: @primary;
            .height(228);
            left: 0;
            top:0;
            @media @mobile{
                .height(170);
             }
          }
        }
        .list-wrap-scroll{
          .height(125);
          
          ul{
            list-style: none;
            li{
              position: relative;
              padding-left: 2rem;
              .font-size(15);
              .line-height(30);
              .Mt;
              &::before{
                content: "\f00c";
                position: absolute;
                color: @primary;
                font-weight: 900;
                font-family: 'Font Awesome 5 Free';
                left:0;
                top:0;
              }
            }
          }
          .mCSB_scrollTools {
            width: 0.375rem;
        }
        .mCSB_inside>.mCSB_container {
          margin-right: 0.5rem;
      }
          .mCSB_scrollTools .mCSB_draggerRail {
            background-color: @secondary;
        }
        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
          background-color: @black;
          filter: "alpha(opacity=17)";
          -ms-filter: "alpha(opacity=17)";
      }
        }
        
    }
    }
    .slick-prev,
		.slick-next {
    position: absolute;
		.font-size(30);
   // bottom: -2rem;
    .pos-centerY;
    top: 44%;
		cursor: pointer;
		.width(21);
		.height(42);
		background-size: contain;
    z-index: 4;
    @media @mobile{
      top: unset;
       bottom: -2rem;
    }
    }
		.slick-prev{
		  background: url(../img/svg/arrow-left.svg) no-repeat 100% 50%;
      //left: 43%;
      left: -1rem;
		  @media @mobile{
			left: 1rem;
		  }
		}
		.slick-next {
      background: url(../img/svg/arrow-right.svg) no-repeat 100% 50%;
    //  right: 43%;
    right: -1rem;
		@media @mobile{
		right: 1rem;
		  }
		}
  }
  
    
 
}
.ref{
  a{
    .MtB;
    .ttu;
    position: relative;
    .font-size(14);
    .line-height(25);
    &:hover{
      text-decoration: none;
      &::before{
        background-color:@primary;
      }
    }
    &::before{
      content: "";
      width: 100%;
      height: 2px;
      background-color: @white;
      position: absolute;
      bottom: -0.25rem;
      left: 0;
    }
  }
}

.contact-block{
  background-color: @secondary;
  .padding(84,0,75,0);
  @media @tablet-portrait{
    .padding(64,0,65,0);
  }
  @media @mobile{
    .padding(40,0,40,0);
  }
  .text{
    padding-left: 3.125rem;
    display: flex;
    align-items: center;
    position: relative;
    span{
      color: @white;
      .font-size(50);
      letter-spacing: 0.18em;
      .MtCB;
      .ttu;
      white-space: nowrap;
      z-index: 2;
      @media @tablet-portrait{
        .font-size(35);
        padding-left: 1rem;
      }
      @media @mobile{
        .font-size(25);
        padding-left: 1rem;
      }
    }
    a{
      z-index: 2;
      margin-left: 2rem;
      overflow: hidden;
      .height(18);
      display: flex;
      align-items: center;
      .width(490);
      will-change: auto;
      transition: all .3s ease;
      @media @mobile {
        width: 100%;
      }
      &:hover{
        transform: translateX(10px)
      }
    }
    .line{
      .width(480);
      height: 2px;
      background-color: @primary;
      position: relative;
      @media @tablet-portrait{
        .width(280);
      }
      @media @mobile{
        .width(80);
      }
      &:after{
        content: "";
        position: absolute;
        right: -13px;
        .pos-centerY;
        border: 8px solid transparent;
        border-left: 16px solid @primary;
      }
    }
  }
  .logo-animate{
    .width(245);
    height: 100%;
    position: absolute;
    .left(-140);
    .top(-72);
    z-index: 1;
    @media only screen and (min-width: 1000.99px) and (max-width: 1202.98px){
      .left(-50);
    }
    @media @tablet-portrait{
      .width(190);
      left: -2.5rem;
      .top(-55);
    }
    @media @mobile{
      .width(120);
      left: -0.5rem;
      .top(-35);
    }
     object{
        width: 100%;
      
      }
     
  }
}
.representation-section{
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    .padding(158,0,180,0);
    @media @mobile{
      .padding(50,0,50,0);
      background-attachment: scroll;
    }
    .block-ttl{
      .font-size(140);
      .MtCB;
      color:#172017;
      .ttu;
      line-height: 6rem;
      text-align: center;
      text-shadow: -3.126px 17.727px 59px rgba(0, 0, 0, 0.5);
      opacity: 0.9;
      @media @desktop{
        .font-size(120);
      }
      @media @tablet{
        .font-size(90);
        color: rgba(255, 255, 255, 0.4);
        opacity: 0.6;
      }
      @media @mobile{
        .font-size(70);
      color: rgba(255, 255, 255, 0.4);
      opacity: 0.6;
      }
      @media @mobile-xs{
        .font-size(40);
      }
   }
   .representation-wrap{
     z-index: 2;
     display: flex;
     align-items: center;
     flex-wrap: wrap;
     margin-top: -5.5rem;
     position: relative;
     @media @tablet-portrait,@mobile{
      margin-top: 0;
     }
     
    
     .representation-block{
        width: 50%;
        @media @mobile{
          width: 100%;
        }
        &.left-side{
          display: flex;
          justify-content: flex-end;
          overflow-x: hidden;
          .open-text-block{
            @media @mobile{
              margin-right: -0.5rem;
            }
         }
       }
        &.right-side{
        display: flex;
        justify-content: flex-start;
        overflow-x: hidden;
     .open-text-block{
       @media @mobile{
         margin-left: -0.5rem;
       }
        
      }
     .text-container{
       flex-direction: row-reverse;
       .close-text-block{
         .margin(0,0,0,32);
       }
       .text-wrap{
         padding-left: 3rem;
         @media @mobile {
           padding-left: 0;
         }
         .btn-wrap{
           flex-direction: row-reverse;
           .line{
             margin-right: 2rem;
             margin-left: 0!important;
           }
         }
       }
     }
    }
        .open-text-block{
          transition: all .3s ease;
          width: 100%;
          img{
            width: 100%;
          }
        }
        
         .text-container{
           display: none;
           transition: all .3s ease;
           .width(610);
           justify-content: flex-start;
           @media @tablet,@mobile{
             width: 90%;
             margin: 0 auto;
             margin-bottom: 1rem;
           }
           .close-text-block{
             .width(27);
             margin-top: 0.5rem;
             .height(27);
             display: block;
             margin-right: 2rem;
             img{
               .width(27);
             .height(27);
             }
           }
           .text-wrap{
             padding-right: 1rem;
             .text-scrolling{
               .height(320);
               p{
                 .Mt;
                 .font-size(15);
                 .line-height(30);
                 margin-bottom: 2.375rem;
               }
             }
             .btn-wrap{
               display: flex;
               align-items: center;
               margin-top: 1rem;
               .line{
                 margin-left: 1rem;
                 max-width:17.25rem;
                 width: 100%;
                 height: 2px;
                 background-color: @primary;
                 position: relative;
               }
             }
             
           }
         }
       
      &.active{
        .open-text-block{
         // display: none;
         width: 0;
        }
        .text-container{
          display: flex;
        }
      }
   }

   }
   
   
 
}


  .team-section{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    .padding(156,0,145,0);
    @media @mobile{
      .padding(50,0,50,0);
      background-attachment: scroll;
      background-position: 0 100%;
    }
    .container-big{
      max-width:84.375rem;
      margin: 0 auto;
      position: relative;
      @media @tablet{
        max-width: 90%;
      }
      @media @mobile{
        max-width: 100%;
      }
    }
   .team-slider{
     margin-top: 2.25rem;
     @media @mobile{
       margin-top: 0;
     }
    .item{
      .item-inner{
        .margin(55,23,105,64);
        .padding(30,11,40,239);
        position: relative;
        background-color: @secondary;
        .height(380);
        
        box-shadow: -1.116px 15.961px 20px 0px rgba(0, 0, 0, 0.48);
        @media @tablet{
          .padding(30,11,40,175);
        }
        @media @tablet-portrait{
          .padding(30,11,40,239);
        }
        @media @mobile{
          height: unset;
          .margin(65,30,55,30);
          .padding(170,20,40,40);
        }
        .rhomb {
          position: absolute;
          display: block;
         .width(100);
         .height(100);
          overflow: hidden;
          right: -0.5rem;
          bottom: -0.5rem;
          i{
            background: @primary;
            position: absolute;
          }
          .top{
            width: 100%;
            height: 1px;
            left: 0;
            top: 0;
          }
          .bottom{
            width: 100%;
            height: 1px;
            left: 0;
            bottom: 0;
          }
          .right{
            width: 1px;
            height: 100%;
            right: 0;
            top: 0;
          }
          .left{
            width: 1px;
            height: 100%;
            left: 0;
            bottom: 0;
          }
         }
        .image{
          position: absolute;
          .left(-45);
          .top(-35);
          padding: 0 1.375rem 1.625rem 0;
          @media @mobile{
            .pos-centerX;
            .top(-40);
          }
          .avatar {
            .width(250);
            .height(320);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            box-shadow: -1.116px 15.961px 20px 0px rgba(0, 0, 0, 0.48);
            @media @tablet{
              .width(150);
            .height(200);
            }
            @media @tablet-portrait{
              .width(250);
              .height(320);
            }
            @media @mobile{
              .width(140);
             .height(180);
            }
          }
        }
        h2{
          .MtB;
          .font-size(20);
          .line-height(26);
        }
        .position{
          .font-size(14);
          .line-height(20);
          .Mt;
          margin-bottom: 1.5rem;
        }
        .holder{
          .height(205);
          overflow-y: hidden;
          p{
            .font-size(15);
            .line-height(25);
          }
        }
      }
    }
    .slick-prev,
		.slick-next {
    position: absolute;
		.font-size(30);
	  bottom: -2rem;
		cursor: pointer;
		.width(21);
		.height(42);
		background-size: contain;
		z-index: 4;
    }
		.slick-prev{
		  background: url(../img/svg/arrow-left.svg) no-repeat 100% 50%;
		  left: 43%;
		  @media @mobile{
			left: 23%;
		  }
		}
		.slick-next {
      background: url(../img/svg/arrow-right.svg) no-repeat 100% 50%;
		  right: 43%;
		@media @mobile{
			right: 23%;
		  }
		}

   }
  }
 
  .block-ttl{
    .font-size(140);
    .MtCB;
    color: rgba(255, 255, 255, 0.4);
    .ttu;
    line-height: 6rem;
    text-align: center;
    text-shadow: -3.126px 17.727px 59px rgba(0, 0, 0, 0.5);
    opacity: 0.6;
    @media @mobile{
      .font-size(100);
    }
    @media @mobile-xs{
      .font-size(60);
    }
 
 }

 .services-section{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  .padding(144,0,174,0);
  @media @mobile{
    .padding(50,0,50,0);
    background-attachment: scroll;
  }
  .container-big{
    max-width:84.375rem;
    margin: 0 auto;
    position: relative;
    // @media @desktop-xl{
    //   max-width: 70rem;
    // }
    @media @tablet{
      max-width: 90%;
    }
    @media @mobile{
      max-width: 100%;
    }
  }

  .services-slider{
    margin-top: -2.25rem;
    @media @mobile{
      margin-top: 0;
    }
   .item{
     &:nth-child(2n){
      .item-inner{
        .margin(105,10,60,10);
        @media @mobile{
          .margin(55,30,60,30);
        }
        @media @mobile-xs{
          .margin(15,30,60,30);
        }
        .rhomb {
          position: absolute;
          display: block;
         .width(92);
         .height(101);
          overflow: hidden;
          left: -0.5rem;
          top: unset;
          bottom: -0.5rem;
          i{
            background: @primary;
            position: absolute;
          }
          .top{
            width: 100%;
            height: 1px;
            left: 0;
            display: none;
            top: 0;
          }
          .bottom{
            width: 100%;
            height: 1px;
            left: 0;
            bottom: 0;
            display: block;
          }
          .left{
            width: 1px;
            height: 100%;
            left: 0;
            bottom: 0;
          }
         }
      }
     }
     a{
       &:hover{
         text-decoration: none;
         .image{
          .avatar {
           transform: scale(1.05);
          }
        }
       }
     }
     .item-inner{
       .margin(15,10,60,10);
      // .padding(30,11,40,239);
       position: relative;
       background-color: @secondary;
      // .height(380);
       display: flex;
       flex-direction: column;
       box-shadow: -1.116px 15.961px 20px 0px rgba(0, 0, 0, 0.48);
       @media @mobile{
        .margin(15,30,60,30);
       }
       .rhomb {
         position: absolute;
         display: block;
        .width(92);
        .height(101);
         overflow: hidden;
         left: -0.5rem;
         top: -0.5rem;
         i{
           background: @primary;
           position: absolute;
         }
         .top{
           width: 100%;
           height: 1px;
           left: 0;
           top: 0;
         }
         .bottom{
           width: 100%;
           height: 1px;
           left: 0;
           bottom: 0;
           display: none;
         }
         .left{
           width: 1px;
           height: 100%;
           left: 0;
           bottom: 0;
         }
        }
       .image{
         width: 100%;
         overflow: hidden;
         .avatar {
          // .height(320);
           padding-bottom: 100%;
           background-repeat: no-repeat;
           background-position: 50% 50%;
           background-size: cover;
           transition: all .3s ease;
         }
       }
       .text{
         background-color: @black;
         color:@white;
         .padding(14,10,14,22);

         p{
           .MtB;
            .font-size(20);
            .line-height(26);
            margin: 0;
            .ttu;
         }
       }
     }
   }
   .slick-prev,
   .slick-next {
   position: absolute;
   .font-size(30);
   bottom: -2rem;
   cursor: pointer;
   .width(21);
   .height(42);
   background-size: contain;
   z-index: 4;
   }
   .slick-prev{
     background: url(../img/svg/arrow-left.svg) no-repeat 100% 50%;
     left: 43%;
     @media @mobile{
     left: 23%;
     }
   }
   .slick-next {
     background: url(../img/svg/arrow-right.svg) no-repeat 100% 50%;
     right: 43%;
   @media @mobile{
     right: 23%;
     }
   }

  }

}

}

