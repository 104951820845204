@font-face {
  font-family: 'BankGothic Md BT';
  src: url('../fonts/BankGothicBT-Medium.eot'),
  url('../fonts/BankGothicBT-Medium.woff') format('woff'),
  url('../fonts/BankGothicBT-Medium.woff2') format('woff2'),
  url('../fonts/BankGothicBT-Medium.ttf')  format('truetype');
  font-weight: 500;
  font-style: normal;
}

