.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: @white;
	min-height: 100vh;
	background: @secondary;
	.Mt;
	line-height: 1.1;
	overflow-x: hidden;
	@media (min-width:1441px) {
		font-size: 16px;
	}
	@media (max-width:1440px) {
		font-size: 14px;
	}
	@media (max-width:1280px) {
		font-size: 12px;
	}
	@media (max-width:1024px) {
		font-size: 13px;
	}
	
}
img {
	vertical-align: top;
}
a {
	color: @white;
	text-decoration: none;
	&:hover {
		color: @primary;
		text-decoration: underline;
	}
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(23,23,23,.3);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.no-scroll {
	@media @mobile {
		position: fixed;
		width:100%;
		height: 100%;
		overflow: hidden;
	}
}
.container {
	max-width: 80rem;
	margin: 0 auto;
	padding: 0;
	position: relative;
	@media @desktop-xl{
		max-width: 70rem;
	}
	@media @tablet,@mobile{
		max-width: 90%;
	}
}

.container-sm{
	max-width: 68.75rem;
	margin: 0 auto;
	position: relative;
	@media @tablet,@mobile{
		max-width: 90%;
	}
}

.container-big{
	max-width:84.375rem;
	margin: 0 auto;
	position: relative;
	@media @desktop-xl{
		max-width: 70rem;
	}
	@media @tablet,@mobile{
		max-width: 90%;
	}
}
h2{
	color: @white;
	.font-size(35);
	.line-height(42);
	.ttu;
	margin-bottom: 1.25rem;
  }

p{
	.font-size(15);
	.line-height(30);
	margin-bottom: 1rem;
	color: @white;
}
.btn {
	.line-height(48);
	.ttu;
	background: @primary;
	color:@white;
	.font-size(14);
	.MtB;
	border: 1px solid @primary;
	cursor: pointer;
	border-radius: 1.5625rem;
	.padding(0,24,0,24);
	.transition(all 0.1s ease);
	outline: 0;
	white-space: nowrap;
	min-width: 10.625rem;
	&:hover {
		border-color:@primary;
		background: fade(@primary, 90%);
		color:@white;
		text-decoration: none;
	}
	&:focus {
		box-shadow:none;
		color:@white;
	}

	&.btn-white {
		background: @white;
		color:@primary;
		border-color:@white;
		&:hover {
			background: fade(@white, 90%);
		}
		&:focus,
		&:active {
			background: fade(@white, 90%);
		}
	}
	&.btn-outline-white{
		background: none;
		color:@white;
		border-color:@white;
		&:hover {
			background: fade(@primary, 10%);
		}
		&:focus,
		&:active{
			background: fade(@primary, 10%);
		}
	}
	&.btn-full {
		width:100%;
	}
}


/* loader start */
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: rgba(23,23,23,.9);
}
.ball-grid-pulse {
	.width(57);
}
.ball-grid-pulse > div:nth-child(1) {
	-webkit-animation-delay: 0.22s;
	animation-delay: 0.22s;
	-webkit-animation-duration: 0.9s;
	animation-duration: 0.9s; }
.ball-grid-pulse > div:nth-child(2) {
	-webkit-animation-delay: 0.64s;
	animation-delay: 0.64s;
	-webkit-animation-duration: 1s;
	animation-duration: 1s; }
.ball-grid-pulse > div:nth-child(3) {
	-webkit-animation-delay: -0.15s;
	animation-delay: -0.15s;
	-webkit-animation-duration: 0.63s;
	animation-duration: 0.63s; }
.ball-grid-pulse > div:nth-child(4) {
	-webkit-animation-delay: -0.03s;
	animation-delay: -0.03s;
	-webkit-animation-duration: 1.24s;
	animation-duration: 1.24s; }
.ball-grid-pulse > div:nth-child(5) {
	-webkit-animation-delay: 0.08s;
	animation-delay: 0.08s;
	-webkit-animation-duration: 1.37s;
	animation-duration: 1.37s; }
.ball-grid-pulse > div:nth-child(6) {
	-webkit-animation-delay: 0.43s;
	animation-delay: 0.43s;
	-webkit-animation-duration: 1.55s;
	animation-duration: 1.55s; }
.ball-grid-pulse > div:nth-child(7) {
	-webkit-animation-delay: 0.05s;
	animation-delay: 0.05s;
	-webkit-animation-duration: 0.7s;
	animation-duration: 0.7s; }
.ball-grid-pulse > div:nth-child(8) {
	-webkit-animation-delay: 0.05s;
	animation-delay: 0.05s;
	-webkit-animation-duration: 0.97s;
	animation-duration: 0.97s; }
.ball-grid-pulse > div:nth-child(9) {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
	-webkit-animation-duration: 0.63s;
	animation-duration: 0.63s; }
.ball-grid-pulse > div {
	background-color: @primary;
	.width(15);
	.height(15);
	border-radius: 100%;
	.margin(2,2,2,2);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	float: left;
	-webkit-animation-name: ball-grid-pulse;
	animation-name: ball-grid-pulse;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-delay: 0;
	animation-delay: 0; }

/* loader end */






.form-group {
	margin-bottom: 2.25rem;
	min-height: 2.5rem;
	position: relative;
	.form-control,
	.custom-select {
		height: 2.5rem;
		background: @white;
		border: 0;
		border-bottom:2px solid #d3d3d3;
		border-radius: 0;
		padding: 0  1rem 0 0;
		color:#848484;
		background-color: transparent;
		.font-size(15);
		font-weight: 400;
		transition:  all .3s linear; 
		-webkit-appearance:  none; 
		@media @mobile{
			.font-size(16);
		}
		&:focus{
			border: none;
			border-bottom:2px solid @primary;
			box-shadow: none;
		}
		&:active{
			border: none;
			border-bottom:2px solid @primary;
			box-shadow: none;
		}
		&::placeholder{
			.font-size(15);
			color: #848484;
			transition: 0.3s ease linear;
		}	
		
	}
	.custom-select {
		background: @white url(../img/svg/arrow-down.svg) no-repeat right 0.75rem center;
		background-size: 0.5rem auto;
		@media @desktop{
			background-position: right 0.5rem center;
			padding-right: 0.5rem;
		}
	}
	textarea.form-control{
		padding-top: 1rem;
	  resize: none;
	  .height(150);
	  position: relative;	  
	  padding-bottom: 1.5rem;
	}	
	
	
	.floating-label{
		position:  absolute;
		left:  0; 
		opacity:  0; 
		transition:  all .3s ease; 
		top:0;
		left: 0;
		z-index: 0;
		color: #848484;
		.line-height(12);
		.MtB;
		.font-size(12);
		margin: 0;
	  }
	  input:not(:placeholder-shown) + .floating-label {
		transform:  translateY(-10px); 
		opacity:  1; 
		z-index: 1;
	  }
	  textarea:not(:placeholder-shown) + .floating-label{
		transform:  translateY(-10px); 
		opacity:  1; 
	  }
	   
}


  //animation
@keyframes pulse-round {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    90% {
        transform: scale(1.075);
    }

    100% {
        transform: scale(1);
    }
}
.pulse-round{
	animation: pulse-round 4.5s infinite ease-in-out;
}


@keyframes circle-rotate {
	0% {
	  transform: rotate(0deg);
	}
	50% {
	  transform: rotate(180deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }


 @keyframes pulse-icon {
	0%,
	100% {
	 transform: rotate(-10deg);
  //    transform: scale(1);
	}
	50% {
	  transform: scale(1.1);
	}
  }
   @keyframes pulse-icon-3{
	0%,
	100% {
	 transform: rotate(-10deg);
  	
	}
	50% {
	  transform: scale(1.05);
	  transform: scale(0.9);
	}
  }
  @keyframes pulse-icon-1 {
	0%,
	100% {
	 transform: rotate(5deg);
  //    transform: scale(1);
	}
	50% {
	  transform: scale(1.2);
	}
  }
  @keyframes pulse-icon-2 {
	0%,
	100% {
	 transform: rotate(-5deg);
  //    transform: scale(1);
	}
	50% {
	  transform: scale(1.2);
	}
  }
  @keyframes pulse-icon-enlarge {
	0%,
	100% {
  		transform: scale(0.9);
	}
	50% {
	  transform: scale(1.2);
	}
	75% {
		transform: scale(1.1);
	  }
  }

  @keyframes pulse-icon-enlarge-1 {
	0%,
	100% {
  		transform: scale(1);
	}
	50% {
	  transform: scale(1.08);
	}
  }

  
  a,p,div,h1,h2,h3,h4,h5,span,section{
	&:focus{
		outline: 1px dashed @secondary;
	}
  }

a:hover{
	cursor: pointer;
}

body.modal-open {
	overflow: hidden;
	position: fixed;
	width:100%;
}

@-webkit-keyframes hvr-pulse {
	25%{
		-webkit-transform: rotate(5deg);
	}

	75% {

		-webkit-transform: rotate(5deg);
	}
}
@keyframes hvr-pulse {
	25%{

		transform: rotate(-5deg);
	}
	75% {
		transform: rotate(5deg);
	}
}